<template>
  <div id="configurationJob" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >CONFIGURACIÓN DE PUESTO</v-row
      >
      <v-container fluid class="addForms" style="margin-top: 5% !important">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol formularios">
            <v-row>
              <div class="form-group">
                <label for="job">Puesto</label>
                <select
                  name="puesto"
                  id="puesto"
                  v-model="puesto"
                  class="mt-n5"
                >
                  <option disabled value="0">Selecciona Puesto</option>
                  <option
                    v-for="puesto in puestos"
                    :key="puesto.id"
                    v-bind:value="puesto.id"
                  >
                    {{ puesto.nombrePuesto }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="puesto"
                  >Brinda servicio a clientes y usuarios</label
                >
                <select
                  name="puesto"
                  id="puesto"
                  v-model="servicio"
                  class="mt-n5"
                >
                  <option disabled value="null">Selecciona</option>
                  <option value="1">Sí</option>
                  <option value="0">No</option>
                </select>
              </div>
              <div class="form-group">
                <label for="puesto">Es jefe de otros trabajadores</label>
                <select name="puesto" id="puesto" v-model="jefe" class="mt-n5">
                  <option disabled value="null">Selecciona</option>
                  <option value="1">Sí</option>
                  <option value="0">No</option>
                </select>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" class="mt-12">
            <button class="botonAmarillo mt-5" @click="validacion()">
              Guardar
            </button>
            <router-link to="/questions" id="backSalary">
              <button class="botonAmarilloDerecho">Regresar</button>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Áreas de atención</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Puestos de atención</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de agregar el puesto?
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancel()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Configuración de Puestos</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-sm-center text-md-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      servicio: null,
      jefe: null,
      expiration: false,
      search: "",
      dialog: false,
      salary: "",
      errors: [],
      respuesta: "",
      advice: false,
      error: false,
      confirmation: false,
      puesto: 0,
      puestos: [],
      jobAux: {},
      idEmpresa: "",
      areaNombre: "",
      cortoNombre: "",
      nivel: "",
    };
  },
  methods: {
    alfaNumerico: function(evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    validacion: function() {
      this.errors = [];
      if (this.puesto == 0) {
        this.errors.push("Se debe especificar el puesto.");
      }
      if (this.jefe == null) {
        this.errors.push("Se debe especificar sí el puesto tiene a cargo trabajadores.");
      }
      if (this.servicio == null) {
        this.errors.push("Se debe especificar sí el puesto brinda servicio a clientes y/o usuarios.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el siguiente error:";
        this.error = true;
      }
    },
    cancel() {
      this.puesto = 0;
      this.advice = false;
    },
    aux() {
      this.confirmation = false;
      this.puesto = 0;
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .get(Server + "/puestos/" + this.puesto, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.jobAux = response.data;
          this.show = false;
          this.updatePuesto();
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerPuestos() {
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxPuestos = response.data;
          auxPuestos.forEach((value, index) => {
            if (value.empresaId == localStorage.empresaIdGlobal) {
              this.puestos.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    updatePuesto() {
      this.show = true;
      axios
        .put(
          Server + "/puestos/" + this.puesto,
          {
            Id: parseInt(this.jobAux.id),
            NombrePuesto: this.jobAux.nombrePuesto,
            Clave: this.jobAux.clave,
            AreaReportaId: parseInt(this.jobAux.areaReportaId),
            PuestoReportaId: parseInt(this.jobAux.puestoReportaId),
            NumeroPosiciones: parseInt(this.jobAux.numeroPosiciones),
            EmpresaId: parseInt(this.jobAux.empresaId),
            EsJefe: parseInt(this.jefe),
            PuestoServicioCliente: parseInt(this.servicio),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.puesto = 0;
          this.servicio=null;
          this.jefe=null;
          this.show = false;
          this.confirmation = true;
          this.respuesta = "Configuración de puesto actualizada";
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          this.puesto = 0;
          this.servicio=null;
          this.jefe=null;
          this.confirmation = true;
          this.respuesta = "La configuración del puesto no pudo ser actualizada, intentar de nuevo";
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.obtenerPuestos();
  },
};
</script>
